<template functional>
  <div class="CarSearch__LicenseplateSearch">
    <div v-if="!parent.hideHeadline" :class="['CarSearch__Headline', parent.headlineCssClass]">
      <h3 v-if="parent.headline">{{ parent.headline }}</h3>
      <h3 v-else-if="parent.showManuel">{{ parent.$t('carsearch.licenseplate') }}</h3>
    </div>

    <Search />
    <Messages />
    <div v-if="!parent.showManuel && parent.hideOnDesktop" class="CarSearch__ManuelToggle">
      <span>{{ parent.$t('carsearch.brandAndModel') }}</span>
    </div>

    <div
      v-else-if="!parent.showManuel && parent.isCompact === false"
      class="CarSearch__ManuelToggle"
    >
      <a :href="parent.carUrl" :title="parent.$t('carsearch.brandAndModel')">{{
        parent.$t('carsearch.brandAndModel')
      }}</a>
    </div>

    <FreightInfo />
  </div>
</template>

<script>
import settings from '@settings';

export default {
  name: 'LicensePlateSearch',
};
</script>
