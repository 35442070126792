<template functional>
  <div class="Search">
    <div
      :class="[
        'Input',
        'Input--licenseplate',
        'Input--licenseplate--' + parent.settings.legacy.countryCode,
      ]"
    >
      <input
        :value="parent.licenseplate"
        @keydown.space.prevent
        type="text"
        name="licenseplate"
        placeholder="AB 12345"
        :title="parent.$t('carsearch.licenseplatePlaceholder')"
        pattern="/^(\w{3,7})$/"
        @input="parent.setLicensePlate"
        @keyup.enter="parent.licenseplateSearch"
      />

      <div v-if="parent.settings.legacy.countryCode === 'NO'" class="flag">
        <span></span>
      </div>

      <span class="Input--clear js-empty-input is-hidden"><i class="fal fa-times"></i></span>
    </div>

    <button
      v-if="parent.isCompact === false"
      v-on:click="parent.licenseplateSearch"
      class="Button Button--search"
      :disabled="parent.licenseplate.length === 0"
    >
      <span class="text">{{ parent.$t('carsearch.searchLabel') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Search',
};
</script>
