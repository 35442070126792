<template>
  <div>
    <div :class="['CarSearch', wrapperCssClass, 'js-car-search', working && 'is-loading']">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        class="CarSearch__spinner Spinner Spinner--xl"
      >
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="50"
          cy="50"
          fill="none"
          stroke="#ff708e"
          stroke-width="8"
          r="30"
          stroke-dasharray="70"
        ></circle>
      </svg>

      <LicensePlateSearch />
      <ShowManuel />
    </div>
  </div>
</template>

<script>
/* global Sentry */
import settings from '@settings';
import { mapActions, mapState, mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import { CollapseTransition as Collapse } from '@transitions';
import { Message } from '@components/UI';

import Tracking from '@tracking';

import { getCardataById } from '@services/thansen/car';

import LicensePlateSearch from './components/LicensePlateSearch';
import Search from './components/LicensePlateSearch/components/Search';
import Messages from './components/LicensePlateSearch/components/Messages';
import FreightInfo from './components/LicensePlateSearch/components/FreightInfo';
import ShowManuel from './components/ShowManuel';
import { plateNotification, filterNotification } from './types';

export default {
  name: 'CarSearch',
  components: {
    Collapse,
    Message,
    LicensePlateSearch,
    Search,
    Messages,
    FreightInfo,
    ShowManuel,
  },

  props: {
    wrapperCssClass: {
      required: false,
      default: '',
      type: String,
    },

    headlineCssClass: {
      required: false,
      default: '',
      type: String,
    },

    headline: {
      required: false,
      default: '',
      type: String,
    },

    carUrl: {
      required: true,
      type: String,
    },

    showManuel: {
      required: true,
      type: Boolean,
    },

    isCompact: {
      required: false,
      type: Boolean,
      default: false,
    },

    hideOnDesktop: {
      required: false,
      type: Boolean,
      default: false,
    },

    showUsp: {
      required: false,
      type: Boolean,
      default: false,
    },

    extendedManuelSelects: {
      required: false,
      type: Boolean,
      default: false,
    },

    hideHeadline: {
      required: false,
      default: false,
      type: Boolean,
    },

    group: String,
    destination: String,
    season: String,
    redirectFilter: String,
    startNode: String, // The starting node for the filter options
    lookup: String,
    groupSlug: {
      type: String,
      default: 'group',
    },
  },

  data() {
    return {
      plateNotification,
      filterNotification,
      settings,
      licenseplateInput: '', // Local value for the input
      redirecting: false, // Redirect is triggered
      notification: '', // licenseplate_invalid, filter_error, licenseplate_not_exact, licenseplate_error
    };
  },

  computed: {
    ...mapState('cardata', ['filter', 'search', 'flashNotification']),

    ...mapGetters('cardata', [
      'getFilterOptions',
      'hasFilterOptions',
      'getSearchedLicensePlate',
      'getSearchMatch',
      'getFilterMatch',
      'isSearchExact',
      'getFilterSelectedOption',
      'isFetching',
      'nextOptionType',
    ]),

    // Forcing uppercase to the licenseplate input
    licenseplate() {
      // BUG: https://sentry.io/share/issue/93ece44849ee4597ad446ca896007bd3/
      return this.licenseplateInput.toUpperCase() && this.licenseplateInput.replace(/\s/, '');
    },

    // If vuex is fetching new data or the component has triggerede a redirect
    working() {
      return this.isFetching || this.redirecting;
    },

    licenseplateValid() {
      return this.validateLicenseplate(this.licenseplate);
    },
  },

  watch: {
    getSearchedLicensePlate(newValue, oldValue) {
      this.licenseplateInput = newValue || '';
    },
  },
  created() {
    if (!this.redirectFilter) {
      this.setupFilter(this.startNode)
        .then(response => {})
        .catch(err => {
          this.notification = filterNotification.ERROR;
          console.error(err);
        });
    }

    // Handle if the license plate has previously been set
    if (this.search && this.search.licenseplate) {
      this.licenseplateInput = this.search.licenseplate;
    }
  },

  mounted() {
    if (this.flashNotification) {
      this.notification = this.flashNotification;
      // Clearing the flashnotification after a delay. This will keep the notification visible until refresh og close (by user)
      setTimeout(() => {
        this.clearFlashNotification();
      }, 800);
    }

    if (this.lookup && this.validateLicenseplate(this.lookup)) {
      this.licenseplateInput = this.lookup;
      this.licenseplateSearch();
    } else if (this.lookup.length > 8 && this.lookup.length < 30) {
      this.getByVin(this.lookup)
        .then(status => {
          if (this.getSearchMatch) {
            // Updates the legacy cookie. !Hotfix #01!
            this.updateLegacyCookie(this.getSearchMatch.vehicle_id);
            Tracking.addEvent('CarSearch', 'Search VIN', 'Success', 1);
            this.redirecting = true;
            window.location = this.getRedirectPath(this.getSearchMatch);
          } else {
            // Found, but need more info. Use manuel search
            Tracking.addEvent('CarSearch', 'Search VIN', 'Not exact', 0);

            // Redirect if the CarSearch hasn't got the filter area
            if (this.redirectFilter) {
              this.setFlashNotification('licenseplate_not_exact');
              this.redirecting = true;
              window.location = this.redirectFilter;
            } else {
              this.notification = plateNotification.NOT_EXACT;
            }
          }
        })
        .catch(error => {
          if (error === 'notfound') {
            this.notification = plateNotification.NOT_FOUND;
          } else if (error === 'error') {
            this.notification = plateNotification.ERROR;
          } else if (typeof Sentry !== 'undefined') {
            Sentry.withScope(scope => {
              //scope.setTag('r', this.licenseplate);
              scope.setLevel('fatal');
              Sentry.captureException(error);
            });
          } else {
            console.error(error);
          }
        });
    }
  },
  methods: {
    ...mapActions('cardata', [
      'getByLicensePlate',
      'getByVin',
      'setupFilter',
      'updateFilterByNode',
      'clearSearch',
      'clearFilter',
      'setFlashNotification',
      'clearFlashNotification',
    ]),

    setLicensePlate(event) {
      const newValue = event.target.value;

      if (this.notification !== '') {
        this.clearNotification();
      }

      this.licenseplateInput = newValue ? newValue.toUpperCase() : '';
    },

    validateLicenseplate(licenseplate) {
      return licenseplate.length >= 2 && licenseplate.length <= 7;
    },

    onFilterChange(e) {
      // Tracking
      Tracking.addEvent(
        'CarSearch',
        'Search manuel',
        `Step ${e.target.getAttribute('data-category')}`,
        1,
      );

      this.clearSearch();
      this.clearNotification();
      this.updateFilterByNode({
        n: e.target.value,
        vid: e.target.options[e.target.selectedIndex].getAttribute('data-vid'),
        category: e.target.getAttribute('data-category'),
      })
        .then(resp => {})
        .catch(err => {
          console.error(err);
          this.notification = filterNotification.ERROR;
        });
    },

    licenseplateSearch() {
      this.clearNotification();
      // Checks if the input is a valid licenseplate
      if (!this.licenseplateValid) {
        this.notification = plateNotification.INVALID;
      } else {
        // Fetches cardata from the licensplate
        this.getByLicensePlate(this.licenseplate)
          .then(status => {
            // Two licenseplatesearch components causes a conflict when submitted - !Hotfix
            if (status !== 'success') {
              return false;
            }

            if (this.getSearchMatch) {
              // Updates the legacy cookie. !Hotfix #01!
              this.updateLegacyCookie(this.getSearchMatch.vehicle_id, this.licenseplate);
              Tracking.addEvent('CarSearch', 'Search licenseplate', 'Success', 1);
              this.redirecting = true;
              window.location = this.getRedirectPath(this.getSearchMatch);
            } else {
              // Found, but need more info. Use manuel search
              Tracking.addEvent('CarSearch', 'Search licenseplate', 'Not exact', 0);
              // Redirect if the CarSearch hasn't got the filter area
              if (this.redirectFilter) {
                this.setFlashNotification('licenseplate_not_exact');
                this.redirecting = true;
                window.location = this.redirectFilter;
              } else {
                this.notification = plateNotification.NOT_EXACT;
              }
            }
          })
          .catch(error => {
            if (error === 'notfound') {
              this.notification = plateNotification.NOT_FOUND;
            } else if (error === 'error') {
              this.notification = plateNotification.ERROR;
            } else if (typeof Sentry !== 'undefined') {
              Sentry.withScope(scope => {
                scope.setTag('r', this.licenseplate);
                scope.setLevel('fatal');
                Sentry.captureException(error);
              });
            } else {
              console.error(error);
            }
          });
      }
    },

    getRedirectPath(match) {
      let redirectUrl = match.url;

      if (this.destination) {
        redirectUrl = this.destination;
        redirectUrl += redirectUrl.endsWith('/') ? '' : '/';
        redirectUrl += `t${match.vehicle_id}`;
      }

      // Building the url for a group
      if (this.group) {
        redirectUrl = match.base_url || match.url;
        redirectUrl += redirectUrl.endsWith('/') ? '' : '/';
        redirectUrl += `${this.groupSlug}/`;
        redirectUrl += `t${match.vehicle_id}/`;
        redirectUrl += `g${this.group}`;
      }

      if (this.season) {
        redirectUrl += `${redirectUrl.indexOf('?') !== -1 ? '&' : '?'}sa=${this.season}`;
      }

      return redirectUrl;
    },

    manuelSearch() {
      this.clearNotification();
      if (this.getFilterMatch) {
        this.updateLegacyCookie(this.getFilterMatch.vehicle_id);
        Tracking.addEvent('CarSearch', 'Search manuel', 'Success', 1);
        this.redirecting = true;

        // This informs the api which car has been selected. Used for history.
        getCardataById(this.getFilterMatch.vehicle_id)
          .then(() => {
            window.location = this.getRedirectPath(this.getFilterMatch);
          })
          .catch(e => {
            window.location = this.getRedirectPath(this.getFilterMatch);
          });
      } else {
        // This should not happen.
        this.notification = filterNotification.ERROR;
      }
    },

    /**
     * Clears the notifications
     */
    clearNotification() {
      this.notification = '';
      this.clearFlashNotification(); // Clears the persistant notification
    },

    /**
     * Updates the legacy b cookie. This should be removed sometime in the future.
     * @param {String} t tecdocid
     * @param {String} r licenseplate
     */
    updateLegacyCookie(t, r) {
      let cookieBstr = `t=${t}`;
      if (typeof r !== 'undefined') {
        cookieBstr += `&r=${r}`; // HOTFIX MVN+KBA. New cars redirect fail if the licenseplate (r) is in the cookie.
      }

      Cookies.set('b', cookieBstr, { expires: 240 });
    },
  },
};
</script>
