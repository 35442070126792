<template functional>
  <div v-if="parent.showUsp && parent.settings.legacy.domainCode !== 'VAU'" class="hidden-xs">
    <ul>
      <li>
        <a href="/kundeservice/fragt-og-levering/n-236578225"
          ><i class="fal fa-truck"></i>{{ parent.$t('carsearch.UspFreeDelivery') }}</a
        >
      </li>
      <li>
        <a href="/kundeservice/dag-til-dag-levering/n-236578168"
          ><i class="fal fa-box-open"></i>{{ parent.$t('carsearch.UspDayToDay') }}</a
        >
      </li>
      <li>
        <a href="/kundeservice/365-dages-returret/n-236577899"
          ><i class="fal fa-undo"></i>{{ parent.$t('carsearch.UspYearRound') }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FreightInfo',
};
</script>
