<template functional>
  <div>
    <message
      :title="parent.$t('carsearch.Error1Headline')"
      @close="parent.clearNotification"
      :show="parent.notification === parent.plateNotification.NOT_FOUND"
      type="warning"
      ><span
        v-if="parent.licenseplate"
        v-html="parent.$t('carsearch.Error1Text', { licenseplate: parent.licenseplate })"
      ></span
    ></message>

    <message
      :title="parent.$t('carsearch.Error2Headline')"
      @close="parent.clearNotification"
      :show="parent.notification === parent.plateNotification.NOT_EXACT"
      type="warning"
      ><span
        v-if="parent.licenseplate"
        v-html="parent.$t('carsearch.Error2Text', { licenseplate: parent.licenseplate })"
      ></span
    ></message>
    <message
      :title="parent.$t('carsearch.Error3Headline')"
      @close="parent.clearNotification"
      :show="parent.notification === parent.plateNotification.INVALID"
      type="error"
      ><span v-if="parent.licenseplate">{{ parent.$t('carsearch.Error3Text') }}</span></message
    >
    <message
      :title="parent.$t('carsearch.Error4Headline')"
      @close="parent.clearNotification"
      :show="parent.notification === parent.plateNotification.ERROR"
      type="error"
      ><span v-if="parent.licenseplate">{{ parent.$t('carsearch.Error4Text') }}</span></message
    >
  </div>
</template>

<script>
export default {
  name: 'Messages',
  functional: true,
};
</script>
