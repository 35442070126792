<template functional>
  <div
    v-if="parent.showManuel"
    :class="['CarSearch__ManuelSearch', !parent.showManuel && 'is-hidden']"
  >
    <div :class="['ManuelSearch__Options', parent.showManuel ? 'is-fixed' : undefined]">
      <div :class="['CarSearch__Headline', parent.headlineCssClass]">
        <h3>{{ parent.$t('carsearch.searhVechicleFromBrandAndModel') }}</h3>
      </div>

      <div
        class="choice"
        :class="['choice', { 'is-selected': parent.getFilterSelectedOption('manufacturers') }]"
      >
        <div
          class="Input Input--steps Input--select"
          :class="{ 'is-highlight': parent.nextOptionType === 'manufacturers' }"
        >
          <span class="Step"></span>
          <select data-category="manufacturers" @change="parent.onFilterChange">
            <option value="" selected>{{ parent.$t('carsearch.OptionBrand') }}</option>
            <optgroup
              :label="parent.$t('carsearch.OptionMostPopular')"
              v-if="parent.getFilterOptions('manufacturers', 'is_favorite').length > 0"
            >
              <option
                v-for="(item, index) in parent.getFilterOptions('manufacturers', 'is_favorite')"
                :key="index"
                :value="item.n"
                :selected="item.is_selected"
                :disabled="!parent.hasFilterOptions('manufacturers')"
                v-html="item.name"
                >{{ item.name }}</option
              >
            </optgroup>
            <optgroup :label="parent.$t('carsearch.OptionAllBrands')">
              <option
                v-for="(item, index) in parent.getFilterOptions('manufacturers')"
                :key="index"
                :value="item.n"
                :selected="item.is_selected"
                :disabled="!parent.hasFilterOptions('manufacturers')"
                v-html="item.name"
                >{{ item.name }}</option
              >
            </optgroup>
          </select>
        </div>
      </div>

      <div
        class="choice"
        :class="['choice', { 'is-selected': parent.getFilterSelectedOption('models') }]"
      >
        <div
          class="Input Input--steps Input--select"
          :class="{ 'is-highlight': parent.nextOptionType === 'models' }"
        >
          <span class="Step"></span>
          <select
            data-category="models"
            @change="parent.onFilterChange"
            :disabled="!parent.hasFilterOptions('models')"
          >
            <option value="" selected>{{ parent.$t('carsearch.OptionModel') }}</option>
            <optgroup
              :label="parent.$t('carsearch.OptionMostPopular')"
              v-if="parent.getFilterOptions('models', 'is_favorite').length > 0"
            >
              <option
                v-for="(item, index) in parent.getFilterOptions('models', 'is_favorite')"
                :key="index"
                :value="item.n"
                :selected="item.is_selected"
                :disabled="!parent.hasFilterOptions('models')"
                v-html="item.name"
                >{{ item.name }}</option
              >
            </optgroup>
            <optgroup :label="parent.$t('carsearch.OptionAllModels')">
              <option
                v-for="(item, index) in parent.getFilterOptions('models')"
                :key="index"
                :value="item.n"
                :selected="item.is_selected"
                :disabled="!parent.hasFilterOptions('models')"
                v-html="item.name"
                >{{ item.name }}</option
              >
            </optgroup>
          </select>
        </div>
      </div>

      <collapse
        ><div
          class="choice"
          :class="['choice', { 'is-selected': parent.getFilterSelectedOption('engines') }]"
          v-if="parent.extendedManuelSelects === false && parent.hasFilterOptions('engines')"
        >
          <div
            class="Input Input--steps Input--select"
            :class="{ 'is-highlight': parent.nextOptionType === 'engines' }"
          >
            <span class="Step"></span>
            <select
              data-category="engines"
              @change="parent.onFilterChange"
              :disabled="!parent.hasFilterOptions('engines')"
            >
              <option value="" selected>{{ parent.$t('carsearch.OptionMotor') }}</option>
              <optgroup
                :label="parent.$t('carsearch.OptionMostPopular')"
                v-if="parent.getFilterOptions('engines', 'is_favorite').length > 0"
              >
                <option
                  v-for="(item, index) in parent.getFilterOptions('engines', 'is_favorite')"
                  :key="index"
                  :value="item.n"
                  :selected="item.is_selected"
                  :disabled="!parent.hasFilterOptions('engines')"
                  v-html="item.name"
                  >{{ item.name }}</option
                >
              </optgroup>
              <optgroup :label="parent.$t('carsearch.OptionAll')">
                <option
                  v-for="(item, index) in parent.getFilterOptions('engines')"
                  :key="index"
                  :value="item.n"
                  :selected="item.is_selected"
                  :disabled="!parent.hasFilterOptions('engines')"
                  v-html="item.name"
                  >{{ item.name }}</option
                >
              </optgroup>
            </select>
          </div>
        </div></collapse
      >

      <collapse
        ><div
          class="choice"
          :class="['choice', { 'is-selected': parent.getFilterSelectedOption('production_years') }]"
          v-if="parent.hasFilterOptions('production_years')"
        >
          <div
            class="Input Input--steps Input--select"
            :class="{ 'is-highlight': parent.nextOptionType === 'production_years' }"
          >
            <span class="Step"></span>
            <select
              data-category="production_years"
              @change="parent.onFilterChange"
              :disabled="!parent.hasFilterOptions('production_years')"
            >
              <option value="" selected>{{ parent.$t('carsearch.OptionYear') }}</option>
              <option
                v-for="(item, index) in parent.getFilterOptions('production_years')"
                :key="index"
                :value="item.n"
                :selected="item.is_selected"
                v-html="item.name"
                >{{ item.name }}</option
              >
            </select>
          </div>
        </div></collapse
      >

      <collapse
        ><div
          class="choice"
          :class="['choice', { 'is-selected': parent.getFilterSelectedOption('variants') }]"
          v-if="parent.hasFilterOptions('variants')"
        >
          <div
            class="Input Input--steps Input--select"
            :class="{ 'is-highlight': parent.nextOptionType === 'variants' }"
          >
            <span class="Step"></span>
            <select
              data-category="variants"
              @change="parent.onFilterChange"
              :disabled="!parent.hasFilterOptions('variants')"
            >
              <option value="" selected>{{ parent.$t('carsearch.OptionVariant') }}</option>
              <option
                v-for="(item, index) in parent.getFilterOptions('variants')"
                :key="index"
                :data-vid="item.vehicle_id"
                :value="item.n"
                :selected="item.is_selected"
                v-html="item.name"
                >{{ item.name }}</option
              >
            </select>
          </div>
        </div></collapse
      >

      <message
        title="Der skete en fejl"
        @close="parent.clearNotification"
        :show="parent.notification === parent.filterNotification.ERROR"
      ></message>

      <button
        v-on:click="parent.manuelSearch"
        class="CarSearch__filterGo Button Button--block Button--search"
        :disabled="!parent.getFilterMatch"
      >
        {{ parent.$t('carsearch.searchLabel') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowManuel',
  functional: true,
};
</script>
