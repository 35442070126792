export const plateNotification = {
  NOT_FOUND: 'licenseplate_not_found',
  NOT_EXACT: 'licenseplate_not_exact',
  INVALID: 'licenseplate_invalid',
  ERROR: 'licenseplate_error',
  ERROR2: 'licenseplate_error2',
};

export const filterNotification = {
  ERROR: 'filter_error',
};
